import styled from "styled-components";
import {Helmet} from "react-helmet";
import Container from 'src/components/Container';
import { media } from 'src/utils/media';

export default function PromoPage() {
  return (
    <PageWrapper>
      <Helmet>
      </Helmet>

      <PageContainer>
        <LogoContainer>
          <img src="/promo/logo.svg"/>
        </LogoContainer>

        <HeroContainer>
          <TitleContainer>
            <Title>Электронное QR-меню для вашего ресторана</Title>
            <PreferenceList>
              <li>Открывается по QR-коду</li>
              <li>Обновляется в реальном времени</li>
              <li>Работает на любых устройствах</li>
              <li>Не требует участия программистов и специальных знаний</li>
            </PreferenceList>
            <ButtonGroup>
              <PrimeButton href="https://t.me/pomaprs" target="_blank">Пробовать бесплатно</PrimeButton>
              <LightButton href="/taverns/1" target="_blank">Смотреть демо</LightButton>
            </ButtonGroup>
          </TitleContainer>
          <DemoContainer>
            <img src="/promo/demo.png"/>
          </DemoContainer>
        </HeroContainer>

        <DigitsContainer>
          <DigitsBlock>
            <DigitsTitle>на 18%</DigitsTitle>
            <DigitsDesc>увеличивает средний чек – гости заказывают больше</DigitsDesc>
          </DigitsBlock>
          <DigitsBlock>
            <DigitsTitle>на 30%</DigitsTitle>
            <DigitsDesc>экономия на персонале и обновлении печатного меню</DigitsDesc>
          </DigitsBlock>
        </DigitsContainer>

        <FooterContainer>
          <BottomBar>
            <Support>Сотрудничество, предложения и отзывы – <a href="mailto:poma@updevision.com">poma@updevision.com</a></Support>
            <Copyright>&copy; Copyright 2022 Updevision</Copyright>
          </BottomBar>
        </FooterContainer>
      </PageContainer>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  background-color: #F9F9FF;
  min-height: 100vh;
  color: #44403C;
  letter-spacing: .2px;
`;

const PageContainer = styled(Container)`
  
`;

const LogoContainer = styled.div`
  padding: 50px;
  text-align: center;

  img {
    display: inline-block;
    max-height: 100%;
    height: 30px;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 48px 0;
  margin: 60px 0;

  ${media('<tablet')} {
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
  }
`;

const TitleContainer = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  display: block;
  font-weight: bold;
  font-size: 72px;
  line-height: 85px;

  ${media('<desktop')} {
    font-size: 48px;
    line-height: 55px;
  }
`;

const DemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex-shrink: 0;
  align-items: center;

  img {
    max-height: 700px;
    border-radius: 30px;
  }

  ${media('<tablet')} {
    margin-top: 50px;
  }
`;

const PreferenceList = styled.ul`
  font-size: 18px;
  list-style: none;
  margin-left: 0;
  padding-left: 50px;
  margin-top: 50px;
  margin-bottom: 35px;

  li {
    position: relative;
    margin-bottom: 15px;
  }
  
  li:before {
    content: "□";
    padding-right: 5px;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    top: -3px;
    left: -30px;
  }

  ${media('<tablet')} {
    padding-left: 40px;
  }
`;

const ButtonGroup = styled.div`
`;

const PrimeButton = styled.a`
  background: #FF9847;
  border: 1px solid #44403C;
  padding: 12px 24px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  transition: background-color .2s linear;
  border-radius: 12px;
  font-size: 15px;
  margin-right: 24px;
  text-decoration: none;

  &:link, &:visited, &:hover, &:active {
    color: #44403C;
    text-decoration: none;
  }

  ${media('<tablet')} {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 0;
  }

  &:hover {
    background-color: #FEAD62;
  }
`;

const LightButton = styled.a`
  background: transparent;
  border: 1px solid #44403C;
  padding: 12px 24px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  transition: background-color .2s linear;
  border-radius: 12px;
  font-size: 15px;
  margin-right: 15px;
  text-decoration: none;

  &:link, &:visited, &:hover, &:active {
    color: #44403C;
    text-decoration: none;
  }

  ${media('<tablet')} {
    width: 100%;
    text-align: center;
  }

  &:hover {
    background-color: #efefef;
  }
`;

const DigitsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 60px 0;

  ${media('<tablet')} {
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
  }
`;

const DigitsBlock = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 80px;

  ${media('<desktop')} {
    padding: 10px;
  }
`;

const DigitsTitle = styled.div`
  color: #FB6C42;
  font-size: 72px;
  font-weight: bold;
`;

const DigitsDesc = styled.div`
  font-size: 24px;
`;

const FooterContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 4rem;
  background: rgb(var(--secondary));
  color: rgb(var(--textSecondary));
`;

const Support = styled.p`
  font-size: 1.5rem;
  margin-top: 0.5rem;
`;

const Copyright = styled.p`
  font-size: 1.5rem;
  margin-top: 2rem;
`;

const BottomBar = styled.div`
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;