import styled from 'styled-components';
import { media } from 'src/utils/media';
import { PropsWithChildren } from 'react';
import { Dish } from 'src/types';

export interface MenuItemProps {
    item: Dish;
    onClickMoreBtn(item: Dish): void;
}

export default function MenuItem({ item, onClickMoreBtn }: PropsWithChildren<MenuItemProps>) {
    return (
        <MenuItemRoot onClick={() => onClickMoreBtn(item)}>
            <MenuItemContainer>
                <MenuItemImageRoot image={item.image}></MenuItemImageRoot>
                <MenuItemInfoRoot>
                <MenuItemInfoPrice>{ item.price } ₽</MenuItemInfoPrice>
                <MenuItemInfoName>{ item.title }</MenuItemInfoName>
                </MenuItemInfoRoot>
                <MenuItemButton>Подробнее</MenuItemButton>
            </MenuItemContainer>
        </MenuItemRoot>
    );
}

const MenuItemRoot = styled.div`
  margin: 8px 0 0 8px;
  display: flex;
  border-radius: 24px;
  flex-basis: calc(25% - 12px);
  border: solid 1px #F5F4F2;
  cursor: pointer;

  ${media('<=desktop')} {
    flex-basis: calc(33.33% - 12px);
  }

  ${media('<tablet')} {
    flex-basis: calc(50% - 12px);
  }
`;

const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 6px;
  position: relative;
  border-radius: 24px;
  flex-direction: column;
`;

const MenuItemImageRoot = styled.div<{ image: string }>`
  width: 100%;
  border-radius: 18px;
  margin-bottom: 8px;
  background-image: url("${props => props.image}");
  background-size: cover;
  background-position: center;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const MenuItemInfoRoot = styled.div`
  padding: 0 6px;
  margin-bottom: 8px;
`;

const MenuItemInfoPrice = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  position: relative;
  font-weight: bold;
`;

const MenuItemInfoName = styled.div`
  margin: 4px 0;
  overflow: hidden;
  font-size: 13px;
  max-height: 60px;
  word-break: break-word;
  line-height: 15px;
  text-overflow: ellipsis;
`;

const MenuItemButton = styled.button`
  width: 100%;
  height: 32px;
  color: #21201F;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
  background-color: transparent;
  margin-top: auto;

  box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
  border-radius: 100px;
  border: 1px solid #8ec63f;
  background-color: #fff;

  font-size: 13px;
  line-height: 14px;

  &:hover {
    background: #F1F0ED;
  }
`;