import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Menu/Footer";
import { GlobalStyle } from "./components/GlobalStyle";
import PromoPage from "./promo";
import TavernDetailsPage from "./tavern";

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <div className="App">
          <Routes>
            <Route path="/" element={ <PromoPage/> }/>
            <Route path="/taverns/:tavernId" element={ <TavernDetailsPage/> }/>
          </Routes>
        </div>
      </>
    );
  }
}

export default App;
