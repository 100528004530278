export default function MenuCloseImage() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z"/>
                <g fill="#000" transform="translate(4 4)">
                    <rect width="20" height="2" x="-2" y="7" rx="1" transform="rotate(45 8 8)"/>
                    <rect width="20" height="2" x="-2" y="7" rx="1" transform="rotate(135 8 8)"/>
                </g>
            </g>
        </svg>
    );
  }
  