import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Dish } from "src/types";
import MenuCloseImage from "./MenuCloseImage";

export interface MenuItemModalProps {
    show: boolean;
    onClose(): void;
    item: Dish;
}

export default function MenuItemModal({ show, onClose, item }: PropsWithChildren<MenuItemModalProps>) {
    const [isBrowser, setIsBrowser] = useState(false);
  
    useEffect(() => {
        setIsBrowser(true);
    }, []);
  
    const handleCloseClick = (e) => {
        e.preventDefault();
        onClose();
    };

    return isBrowser && item ? (
        <ModalRoot show={show}>
            <ModalBackdrop onClick={handleCloseClick}></ModalBackdrop>
            <ModalContainer show={show}>
                <ModalInner show={show}>
                    <ModalDataRoot>
                        <ModalDataShadow></ModalDataShadow>
                        <ModalDataContent>
                            <ModelDataImage image={item.image}></ModelDataImage>
                            <ModelDataTitle>{item.title}</ModelDataTitle>
                            <ModelDataСomposition>{item.description}</ModelDataСomposition>
                            <ModelDataPrice>{item.price} ₽</ModelDataPrice>
                        </ModalDataContent>
                        <ModalCloseBtn onClick={handleCloseClick}>
                            <MenuCloseImage></MenuCloseImage>
                        </ModalCloseBtn>
                    </ModalDataRoot>
                </ModalInner>
            </ModalContainer>
        </ModalRoot>
      ) : null;
};

const ModalRoot = styled.div<{ show: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100004;
    background: rgba(0, 0, 0, 0.5);

    transition: visibility .3s, opacity .3s ease;
    visibility: ${ props => props.show ? 'visible' : 'hidden' };
    opacity: ${ props => props.show ? '1' : '0' };
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
`;

const ModalContainer = styled.div<{ show: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
`;

const ModalInner = styled.div<{ show: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 -4px 20px rgb(117 115 111 / 20%);
    min-height: 64px;
    max-height: 95vh;
    border-radius: 24px 24px 0 0;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 700px;
    margin: 0 auto;

    transition: .3s ease transform;
    transform: translateY(100%);
    will-change: transform;

    ${ props => props.show ? 'transform: translateY(0%);' : '' }
`;

const ModalDataRoot = styled.div`
    display: flex;
    flex-wrap: nowrap;
    max-height: 100%;
    flex-direction: column;
    position: relative;
    border-radius: 24px 24px 0 0;
`;

const ModalCloseBtn = styled.div`
    top: 12px;
    right: 12px;
    width: 40px;
    height: 40px;
    padding: 8px;
    z-index: 6;
    position: absolute;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%);
    border-radius: 50%;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
`;

const ModalDataShadow = styled.div`
    background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0) calc(100% - 30px), #fafafa);
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px inset, rgb(0 0 0 / 0%) 0px 0px 0px 0px inset, rgb(0 0 0 / 0%) 0px 0px 0px 0px inset, rgb(0 0 0 / 0%) 0px 0px 0px 0px inset;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    pointer-events: none;
`;

const ModalDataContent = styled.div`
    width: 100%;
    z-index: 3;
    overflow-x: auto;
    overflow-y: auto;
`;

const ModelDataImage = styled.div<{ image: string }>`
  width: 100%;
  background-image: url("${props => props.image}");
  background-size: cover;
  background-position: center;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const ModelDataPrice = styled.div`
    padding: 16px;
    padding-bottom: 50px;
    font-size: 16px;
    line-height: 22px;
    background: #fafafa;
    word-break: break-word;
    font-weight: bold;
`;

const ModelDataTitle = styled.div`
    padding: 16px;
    font-size: 14px;
    background: #fafafa;
    word-break: break-word;
    line-height: 22px;
    font-weight: bold;
`;

const ModelDataСomposition = styled.div`
    padding: 16px;
    padding-top: 0;
    font-size: 14px;
    background: #fafafa;
    word-break: break-word;
    line-height: 22px;
`;