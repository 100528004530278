import { useState, useEffect } from "react";
import styled from "styled-components";
import { EnvVars } from "./env";
import Hero from "./views/TavernPage/Hero";
import Menu from "./views/TavernPage/Menu";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import MenuFooter from "./components/Menu/Footer";

export default function TavernDetailsPage() {
  const { tavernId } = useParams();

  const [tavern, setTavern] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true);

    if (!tavernId) {
      return;
    }

    window.location.hash = "";

    fetch(EnvVars.API + "restaurant/" + tavernId)
      .then((res) => res.json())
      .then((data) => {
        let categories = data.data.categories;
        categories.sort((a, b) => a.order > b.order ? 1 : (a.order < b.order ? -1 : 0));
        categories.forEach(x => {
          x.dishes.sort((a, b) => a.order > b.order ? 1 : (a.order < b.order ? -1 : 0));
        });

        // console.log(data.data);
        setTavern(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
    }, [tavernId])

    return (
      <HomepageWrapper>
        <Helmet>
          <title>{ (isLoading ? '' : tavern.name + ' – ') + EnvVars.SITE_NAME }</title>
        </Helmet>

        <WhiteBackgroundContainer>
          { isLoading 
            ?
              <></>
            :
              <>
                <Hero title={ tavern.name } image={ EnvVars.SOURCE + tavern.bgImage }/>
                <Menu categories={ 
                  tavern.categories.map(x => {
                    return {
                      title: x.name,
                      items: x.dishes.map(d => {
                        return {
                          title: d.name,
                          description: d.description,
                          price: d.price,
                          image: d.image ? EnvVars.SOURCE + d.image : '/menu/empty-dish.svg'
                        }
                      })
                    }
                  })
                }/>
              </>
          }
        </WhiteBackgroundContainer>
        <MenuFooter />
      </HomepageWrapper>
    );
}

const HomepageWrapper = styled.div`
  
`;

const WhiteBackgroundContainer = styled.div`
  
`;