import styled from 'styled-components';
import Container from 'src/components/Container';
import { media } from 'src/utils/media';
import { PropsWithChildren } from 'react';


export interface HeroProps {
  title: string;
  image: string;
}

export default function Hero({ title, image }: PropsWithChildren<HeroProps>) {
  return (
    <HeroWrapper>
      <Contents image={ image }>
        {/* <CustomTitle>{ title }</CustomTitle> */}
      </Contents>
    </HeroWrapper>
  );
}

const HeroWrapper = styled(Container)`
  padding: 0;
  padding-top: 5rem;
  margin-bottom: -24px;

  ${media('<=desktop')} {
    padding-top: 0;
  }
`;

const Contents = styled.div<{ image: string }>`
  height: 800px;
  max-height: 80vh;
  background-image: url("${props => props.image}");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  ${media('<=desktop')} {
    height: 600px;
    border-radius: 0;
  }

  ${media('<tablet')} {
    height: 300px;
  }
`;

const CustomTitle = styled.h1`
  color: white;
  margin: 30px 80px;
  margin-bottom: 80px;
  font-size: 60px;
  font-weight: bold;
  line-height: 1.08;

  ${media('<=desktop')} {
    font-size: 40px;
  }

  ${media('<tablet')} {
    font-size: 30px;
    margin: 30px 30px;
    margin-bottom: 60px;
  }
`;
