import styled from 'styled-components';
import Container from '../Container';

type SingleFooterListItem = { title: string; href: string };
type FooterListItems = SingleFooterListItem[];
type SingleFooterList = { title: string; items: FooterListItems };
type FooterItems = SingleFooterList[];

export default function MenuFooter() {
  return (
    <FooterWrapper>
      <Container>
        <BottomBar>
          <Support>Сотрудничество, предложения и отзывы – <a href="mailto:poma@updevision.com">poma@updevision.com</a></Support>
          <Copyright>&copy; Copyright 2022 Updevision</Copyright>
        </BottomBar>
      </Container>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  padding-top: 5rem;
  padding-bottom: 4rem;
  background: rgb(var(--secondary));
  color: rgb(var(--textSecondary));
`;

const Support = styled.p`
  font-size: 1.5rem;
  margin-top: 0.5rem;
`;

const Copyright = styled.p`
  font-size: 1.5rem;
  margin-top: 2rem;
`;

const BottomBar = styled.div`
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;
