import styled from 'styled-components';
import Container from 'src/components/Container';
import { media } from 'src/utils/media';
import MenuCategory from 'src/components/Menu/MenuCategory';
import { PropsWithChildren, useState } from 'react';
import { Dish } from 'src/types';
import MenuItemModal from 'src/components/Menu/MenuItemModal';

export interface DishProps {
  title: string;
  description: string;
  price: number;
  image: string;
}

export interface CategoryProps {
  title: string;
  items: DishProps[];
}

export interface MenuProps {
  categories: CategoryProps[];
}

export default function Menu({ categories }: PropsWithChildren<MenuProps>) {
  const [showModal, setShowModal] = useState(false);
  const [dishModal, setDishModal] = useState(null);

  function onClickMoreBtn(item: Dish) {
    setDishModal(item);
    setShowModal(true);
  }

  return (
    <HeroWrapper>
      <Contents>
          <MobileStickyRoot>
            <PageMenuPanelRoot>
                <CategoryCarouselRoot>
                    <CategoryCarouselScroll>
                        <CategoryCarouselList>
                            <CategoryCarouselItem_Empty></CategoryCarouselItem_Empty>
                            { 
                              categories.map((x, i) => 
                                <CategoryCarouselItem key={i} onClick={() => { location.hash = "#category" + i; }}>{x.title}</CategoryCarouselItem>
                              ) 
                            }
                            <CategoryCarouselItem_Empty></CategoryCarouselItem_Empty>
                        </CategoryCarouselList>
                    </CategoryCarouselScroll>
                </CategoryCarouselRoot>
            </PageMenuPanelRoot>
          </MobileStickyRoot>

          <MenuRoot>
            {
              categories.map((x, i) => 
                <div id={"category" + i} key={i}>
                  <MenuCategory title={x.title} items={x.items} onClickMoreBtn={onClickMoreBtn} key={i}></MenuCategory>
                </div>
              )
            }
          </MenuRoot>

          <MenuItemModal onClose={() => setShowModal(false)} show={showModal} item={dishModal}>
            { dishModal ? dishModal.price + ' Rub' : '' }
          </MenuItemModal>
      </Contents>
    </HeroWrapper>
  );
}

const HeroWrapper = styled(Container)`
  padding: 0;
  background: black;
  border-radius: 24px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: white;
  border: solid 1px #eeeeee;

  ${media('<=desktop')} {
    padding-top: 0;
    border: 0;
  }
`;

const Contents = styled.div`

`;

const MobileStickyRoot = styled.div`
  top: 0px;
  width: 100%;
  position: sticky;
  font-size: 18px;
  z-index: 2;
  background-color: white;
  border-radius: 24px;
`;

const PageMenuPanelRoot = styled.div`
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
`;

const CategoryCarouselRoot = styled.div`
  width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
`;

const CategoryCarouselScroll = styled.div`
  height: 50px;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const CategoryCarouselList = styled.ul`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0;
`;

const CategoryCarouselItem_Empty = styled.span`
  min-width: 15px;
  min-height: 1px;
`;

const CategoryCarouselItem = styled.li`
  color: #21201F;
  height: 40px;
  display: flex;
  padding: 0 14px;
  font-size: 16px;
  max-width: 80vw;
  box-sizing: border-box;
  align-items: center;
  line-height: 40px;
  white-space: nowrap;
  transition-duration: 200ms;
  transition-property: opacity;

  &:hover {
      background: #F1F0ED;
      border-radius: 16px;
      transition-duration: 200ms;
      transition-property: width;
      cursor: pointer;
  }
`;

const MenuRoot = styled.div`
  
`;